import { DeepPartial } from '@hp/core/shared';

/**
 *  Recursively merge properties and returns new object
    obj1 &lt;- obj2 [ &lt;- ... ]. The last one wins.
    
 */
export const merge = <T>(
  objects: DeepPartial<T>[],
  excludes: any[] = [],
  options: { ignoreNotExistingProps?: boolean } = {},
) => {
  //inspired by https://stackoverflow.com/questions/171251/how-can-i-merge-properties-of-two-javascript-objects-dynamically
  const dst = {},
    args = [].splice.call(objects, 0);
  let src, p;
  let n = 0;
  while (args.length > 0) {
    src = args.splice(0, 1)[0];
    if (toString.call(src) == '[object Object]') {
      for (p in src) {
        if (src.hasOwnProperty(p) && !excludes.includes(src[p])) {
          if (
            options?.ignoreNotExistingProps &&
            n > 0 &&
            dst[p] === undefined
          ) {
            //nothing - we don't copy item which is missing on target
          } else if (toString.call(src[p]) == '[object Object]') {
            dst[p] = merge([dst[p] || {}, src[p]], excludes, {
              ...options,
              ignoreNotExistingProps:
                n > 0 ? options.ignoreNotExistingProps : false,
            });
          } else {
            dst[p] = src[p];
          }
        }
      }
    }
    n++;
  }

  return dst as T;
};
