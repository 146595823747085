declare global {
  interface Window {
    dataLayer: Array<Record<string, unknown>>;
  }
}

type DataLayerItem<T extends string> = Partial<Record<string, unknown>> &
  Record<T, unknown>;

export const addDataLayerItem = <T extends string = 'event'>(
  item: DataLayerItem<T>,
) => {
  window?.dataLayer?.push(item);
};

export const GTMPageView = (url: string) => {
  const pageEvent: DataLayerItem<'event' | 'page'> = {
    event: 'pageview',
    page: url,
  };
  addDataLayerItem(pageEvent);
};

export const changeDataLayerItem = <T extends string>(
  item: DataLayerItem<T>,
  key: T,
) => {
  const dl = window.dataLayer;
  const indexesToDelete = dl
    .map((x, i) => ({ i, toDel: !!x[key] }))
    .filter((x) => x.toDel)
    .map((x) => x.i);
  indexesToDelete.forEach((i) => dl.splice(i, 1));
  dl.push(item);
};
