// eslint-disable-next-line @typescript-eslint/ban-types
const countProps = (obj: Object) => {
  let count = 0;
  for (const k in obj) {
    if (obj.hasOwnProperty(k)) {
      count++;
    }
  }
  return count;
};

export const objectEquals = <T>(v1: T, v2: T, log = false) => {
  if (v1 === v2) return true;

  if (typeof v1 !== typeof v2) {
    if (log) console.log('different types:', v1, v2);
    return false;
  }

  if (typeof v1 === 'function') {
    return v1.toString() === v2.toString();
  }

  if (v1 instanceof Object && v2 instanceof Object) {
    if (countProps(v1) !== countProps(v2)) {
      if (log)
        console.log(
          'keys are not equals: ',
          Object.keys(v1).sort(),
          Object.keys(v2).sort(),
        );
      return false;
    }

    for (const k in v1) {
      if (!objectEquals(v1[k], v2[k])) return false;
    }
    return true;
  }

  if (log) console.log('different values:', v1, v2);
  return false;
};

export const fromEntries = <T = any>(
  iterable: Iterable<readonly [PropertyKey, T]>,
): { [k: string]: T } => {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
};

export const cleanObject = <
  TObj extends Record<string, unknown>,
  TDefault = null
>(
  obj: TObj,
  emptyObjectDefaultValue: TDefault,
  predicate: (value: any) => boolean = (value) => !!value,
) => {
  const entries = Object.entries(obj).filter(predicate);
  return entries.length
    ? (fromEntries(entries) as Partial<TObj>)
    : emptyObjectDefaultValue;
};
