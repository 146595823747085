import { Language } from '@hp/core/shared';
import { I18n } from '@lingui/core';
import { Trans } from '@lingui/react';
import React from 'react';

export enum DayOfTheWeek {
  'monday' = 'monday',
  'tuesday' = 'tuesday',
  'wednesday' = 'wednesday',
  'thursday' = 'thursday',
  'friday' = 'friday',
  'saturday' = 'saturday',
  'sunday' = 'sunday',
}
const inlineDayNames: Record<
  DayOfTheWeek | number,
  { key: string; trans: unknown; abbr: unknown }
> = {
  [DayOfTheWeek.monday]: {
    key: 'days.monday',
    trans: <Trans id="days.monday">pondělí</Trans>,
    abbr: <Trans id="days.monday.abbr">po</Trans>,
  },
  [DayOfTheWeek.tuesday]: {
    key: 'days.tuesday',
    trans: <Trans id="days.tuesday">úterý</Trans>,
    abbr: <Trans id="days.tuesday.abbr">út</Trans>,
  },
  [DayOfTheWeek.wednesday]: {
    key: 'days.wednesday',
    trans: <Trans id="days.wednesday">středa</Trans>,
    abbr: <Trans id="days.wednesday.abbr">stř</Trans>,
  },
  [DayOfTheWeek.thursday]: {
    key: 'days.thursday',
    trans: <Trans id="days.thursday">čtvrtek</Trans>,
    abbr: <Trans id="days.thursday.abbr">čt</Trans>,
  },
  [DayOfTheWeek.friday]: {
    key: 'days.friday',
    trans: <Trans id="days.friday">pátek</Trans>,
    abbr: <Trans id="days.friday.abbr">pá</Trans>,
  },
  [DayOfTheWeek.saturday]: {
    key: 'days.saturday',
    trans: <Trans id="days.saturday">sobota</Trans>,
    abbr: <Trans id="days.saturday.abbr">so</Trans>,
  },
  [DayOfTheWeek.sunday]: {
    key: 'days.sunday',
    trans: <Trans id="days.sunday">neděle</Trans>,
    abbr: <Trans id="days.sunday.abbr">ne</Trans>,
  },
};
const allDays = Object.values(DayOfTheWeek);
allDays.forEach((day, index) => {
  inlineDayNames[(index + 1) % 7] = inlineDayNames[day];
});

const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

/** gets day of name
 * @param day enum value OR number, where 0=sunday, 1=monday, etc..
 * @param capitalize true = first letter will be uppercased
 */
export const getDayName = (
  day: number | DayOfTheWeek,
  _language: Language,
  i18n: I18n,
  capitalize: boolean,
  abbreviation = false,
) => {
  const value = i18n._(
    `${inlineDayNames[day].key}${abbreviation ? `.abbr` : ''}`,
  );

  return capitalize
    ? abbreviation
      ? value.toUpperCase()
      : capitalizeFirstLetter(value)
    : value;
};
