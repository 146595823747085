import { DynamicConfigRoot } from '@hp/core/shared';

import { getKey } from './hooks/useLocalStorage';
import { safeLocalStorage } from './storage';

export const devStorageKey = 'dev';

export type DevStorageData = {
  features: Partial<DynamicConfigRoot['features']>;
};

declare global {
  interface Window {
    /* subset of dpdDynCfg, but it is available just from the beginning, see _document.tsx */
    dpdFeatures: DynamicConfigRoot['features'];
    dpdServerTime: number;
    dpdTzOffsetDelta: number;
  }
}

export const hasFeature = (
  featureName: keyof DynamicConfigRoot['features'],
) => {
  if (typeof window === 'undefined') {
    const msg = `using hasFeature is not supported (reading key ${featureName}).`;
    console.error(msg);
    throw new Error(msg);
  }
  const features = window.dpdFeatures;

  const personalSet = safeLocalStorage.getItem(getKey(devStorageKey));
  if (personalSet) {
    const data = JSON.parse(personalSet) as DevStorageData;
    return data.features[featureName] ?? features[featureName];
  }
  return features[featureName];
};

export const getAllFeatures = (features: DynamicConfigRoot['features']) => {
  const res =
    typeof window === 'undefined'
      ? null
      : safeLocalStorage.getItem(getKey(devStorageKey));
  if (typeof window === 'undefined')
    console.warn(
      'using getAllFeatures on the server can returns unexpected results, because client can override it.',
    );
  if (res) {
    const data = JSON.parse(res) as DevStorageData;
    return { ...features, ...data.features };
  }
  return features;
};
