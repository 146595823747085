/* eslint-disable */ 
import * as CoreTypes from '@hp/core/shared/graphql/generated/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type AddressQueryVariables = CoreTypes.Exact<{
  id: CoreTypes.Scalars['UUID'];
}>;


export type AddressQuery = { address?: CoreTypes.Maybe<Pick<CoreTypes.Address, 'id' | 'type' | 'name' | 'name2' | 'ico' | 'dic' | 'street' | 'city' | 'zip' | 'country' | 'email' | 'phonePrefix' | 'phone' | 'forInvoicing'>> };

export type AddressesQueryVariables = CoreTypes.Exact<{
  first?: CoreTypes.Maybe<CoreTypes.Scalars['Int']>;
  offset?: CoreTypes.Maybe<CoreTypes.Scalars['Int']>;
}>;


export type AddressesQuery = { addresses?: CoreTypes.Maybe<Array<CoreTypes.Maybe<Pick<CoreTypes.Address, 'id' | 'type' | 'name' | 'name2' | 'ico' | 'dic' | 'street' | 'city' | 'zip' | 'country' | 'email' | 'phonePrefix' | 'phone' | 'forInvoicing'>>>> };

export type BanksQueryVariables = CoreTypes.Exact<{ [key: string]: never; }>;


export type BanksQuery = { banks?: CoreTypes.Maybe<Array<CoreTypes.Maybe<Pick<CoreTypes.Bank, 'code' | 'name'>>>> };

export type CaseQueryVariables = CoreTypes.Exact<{
  id: CoreTypes.Scalars['UUID'];
}>;


export type CaseQuery = { case?: CoreTypes.Maybe<(
    Pick<CoreTypes.CaseData, 'doNotTip' | 'fragile' | 'id' | 'language' | 'sendHow' | 'sendWhen' | 'status' | 'tracking' | 'srcPudoId'>
    & { cashOnDelivery?: CoreTypes.Maybe<(
      Pick<CoreTypes.CashOnDelivery, 'cashOnDeliveryValue' | 'variableSymbol'>
      & { account: Pick<CoreTypes.Account, 'bankCode' | 'number' | 'iban' | 'swift'> }
    )>, items: Array<Pick<CoreTypes.ParcelPartDescription, 'size' | 'weight' | 'parcelNumber' | 'parcelValue'>>, price: Pick<CoreTypes.Price, 'anotherCountry' | 'basePrice' | 'cashOnDelivery' | 'courierDelivery' | 'courierPickup' | 'extraInsurance' | 'total'>, recipient: Pick<CoreTypes.Recipient, 'city' | 'companyName' | 'country' | 'dic' | 'email' | 'ico' | 'name' | 'note' | 'phone' | 'phonePrefix' | 'postalCode' | 'street' | 'type'>, sender: Pick<CoreTypes.Sender, 'city' | 'cityInvoice' | 'country' | 'countryInvoice' | 'dic' | 'dicInvoice' | 'email' | 'ico' | 'icoInvoice' | 'name' | 'nameInvoice' | 'note' | 'phone' | 'phonePrefix' | 'postalCode' | 'postalCodeInvoice' | 'street' | 'streetInvoice' | 'type' | 'typeInvoice'>, utm?: CoreTypes.Maybe<Pick<CoreTypes.Utm, 'campaign' | 'content' | 'medium' | 'source'>>, destAddress?: CoreTypes.Maybe<Pick<CoreTypes.PlaceAddress, 'id' | 'type' | 'name' | 'street' | 'city' | 'postalCode' | 'country'>> }
  )> };

export type CasesQueryVariables = CoreTypes.Exact<{
  offset?: CoreTypes.Maybe<CoreTypes.Scalars['Int']>;
  limit?: CoreTypes.Maybe<CoreTypes.Scalars['Int']>;
}>;


export type CasesQuery = { cases?: CoreTypes.Maybe<(
    Pick<CoreTypes.CasesPayload, 'count'>
    & { cases: Array<CoreTypes.Maybe<(
      Pick<CoreTypes.CaseData, 'id' | 'sendHow' | 'sendWhen' | 'status' | 'doNotTip' | 'fragile' | 'language' | 'srcPudoId'>
      & { recipient: Pick<CoreTypes.Recipient, 'name' | 'street' | 'city' | 'postalCode' | 'country'>, sender: Pick<CoreTypes.Sender, 'name' | 'street' | 'city' | 'postalCode' | 'country'>, price: Pick<CoreTypes.Price, 'anotherCountry' | 'basePrice' | 'cashOnDelivery' | 'courierPickup' | 'courierDelivery' | 'extraInsurance' | 'total'>, items: Array<Pick<CoreTypes.ParcelPartDescription, 'size' | 'weight' | 'parcelNumber' | 'parcelValue'>>, destAddress?: CoreTypes.Maybe<Pick<CoreTypes.PlaceAddress, 'id' | 'type' | 'name' | 'street' | 'city' | 'postalCode' | 'country'>> }
    )>> }
  )> };

export type ConfigQueryVariables = CoreTypes.Exact<{ [key: string]: never; }>;


export type ConfigQuery = { config?: CoreTypes.Maybe<Pick<CoreTypes.ConfigData, 'data'>> };

export type ConsentQueryVariables = CoreTypes.Exact<{ [key: string]: never; }>;


export type ConsentQuery = Pick<CoreTypes.Query, 'consent'>;

export type CreateCaseMutationVariables = CoreTypes.Exact<{
  data: CoreTypes.CaseDataInput;
}>;


export type CreateCaseMutation = { createCase?: CoreTypes.Maybe<(
    Pick<CoreTypes.CaseData, 'doNotTip' | 'fragile' | 'id' | 'language' | 'sendHow' | 'sendWhen' | 'status' | 'tracking' | 'srcPudoId'>
    & { cashOnDelivery?: CoreTypes.Maybe<(
      Pick<CoreTypes.CashOnDelivery, 'cashOnDeliveryValue' | 'variableSymbol'>
      & { account: Pick<CoreTypes.Account, 'bankCode' | 'number' | 'iban' | 'swift'> }
    )>, items: Array<Pick<CoreTypes.ParcelPartDescription, 'size' | 'weight' | 'parcelNumber' | 'parcelValue'>>, price: Pick<CoreTypes.Price, 'anotherCountry' | 'basePrice' | 'cashOnDelivery' | 'courierPickup' | 'courierDelivery' | 'extraInsurance' | 'total'>, recipient: Pick<CoreTypes.Recipient, 'city' | 'companyName' | 'country' | 'dic' | 'email' | 'ico' | 'name' | 'note' | 'phone' | 'phonePrefix' | 'postalCode' | 'street' | 'type'>, sender: Pick<CoreTypes.Sender, 'city' | 'cityInvoice' | 'country' | 'countryInvoice' | 'dic' | 'dicInvoice' | 'email' | 'ico' | 'icoInvoice' | 'name' | 'nameInvoice' | 'note' | 'phone' | 'phonePrefix' | 'postalCode' | 'postalCodeInvoice' | 'street' | 'streetInvoice' | 'type' | 'typeInvoice'>, utm?: CoreTypes.Maybe<Pick<CoreTypes.Utm, 'campaign' | 'content' | 'medium' | 'source'>>, destAddress?: CoreTypes.Maybe<Pick<CoreTypes.PlaceAddress, 'id' | 'type' | 'name' | 'street' | 'city' | 'postalCode' | 'country'>> }
  )> };

export type CreatePaymentMutationVariables = CoreTypes.Exact<{
  redirectUrl: CoreTypes.Scalars['String'];
  id: CoreTypes.Scalars['UUID'];
}>;


export type CreatePaymentMutation = { createPayment?: CoreTypes.Maybe<Pick<CoreTypes.PayUCreateOrderResponse, 'orderId' | 'redirectUri'>> };

export type DeleteAccountMutationVariables = CoreTypes.Exact<{
  reason: CoreTypes.Scalars['String'];
}>;


export type DeleteAccountMutation = Pick<CoreTypes.Mutation, 'deleteAccount'>;

export type DeleteAddressMutationVariables = CoreTypes.Exact<{
  id: CoreTypes.Scalars['UUID'];
}>;


export type DeleteAddressMutation = Pick<CoreTypes.Mutation, 'deleteAddress'>;

export type PaymentQueryVariables = CoreTypes.Exact<{
  id: CoreTypes.Scalars['UUID'];
}>;


export type PaymentQuery = { payment?: CoreTypes.Maybe<Pick<CoreTypes.PayURequest, 'orderId' | 'status'>> };

export type PickupTimeQueryVariables = CoreTypes.Exact<{
  code: CoreTypes.Scalars['String'];
}>;


export type PickupTimeQuery = { pickupTime?: CoreTypes.Maybe<Pick<CoreTypes.PickupTime, 'fromHour' | 'toHour'>> };

export type ProfileQueryVariables = CoreTypes.Exact<{ [key: string]: never; }>;


export type ProfileQuery = { profile?: CoreTypes.Maybe<(
    Pick<CoreTypes.Profile, 'email' | 'emailVerified' | 'phone' | 'phonePrefix' | 'name'>
    & { account?: CoreTypes.Maybe<Pick<CoreTypes.ProfileAccount, 'bankCode' | 'number'>> }
  )> };

export type ProfileWithAdressessQueryVariables = CoreTypes.Exact<{ [key: string]: never; }>;


export type ProfileWithAdressessQuery = { profile?: CoreTypes.Maybe<(
    Pick<CoreTypes.Profile, 'email' | 'emailVerified' | 'phone' | 'phonePrefix' | 'name'>
    & { account?: CoreTypes.Maybe<Pick<CoreTypes.ProfileAccount, 'bankCode' | 'number'>> }
  )>, addresses?: CoreTypes.Maybe<Array<CoreTypes.Maybe<Pick<CoreTypes.Address, 'id' | 'type' | 'name' | 'name2' | 'ico' | 'dic' | 'street' | 'city' | 'zip' | 'country' | 'email' | 'phonePrefix' | 'phone' | 'forInvoicing'>>>> };

export type QrCodesQueryVariables = CoreTypes.Exact<{
  id: CoreTypes.Scalars['UUID'];
}>;


export type QrCodesQuery = Pick<CoreTypes.Query, 'qrCodes'>;

export type RouteQueryVariables = CoreTypes.Exact<{
  country: CoreTypes.Country;
  zip: CoreTypes.Scalars['String'];
}>;


export type RouteQuery = { route?: CoreTypes.Maybe<Pick<CoreTypes.RouteDetail, 'depot' | 'mpsAllowed' | 'sort'>> };

export type SaveAddressMutationVariables = CoreTypes.Exact<{
  address: CoreTypes.SaveAddressInput;
}>;


export type SaveAddressMutation = { saveAddress?: CoreTypes.Maybe<Pick<CoreTypes.Address, 'id' | 'type' | 'name' | 'name2' | 'ico' | 'dic' | 'street' | 'city' | 'zip' | 'country' | 'email' | 'phonePrefix' | 'phone' | 'forInvoicing'>> };

export type SaveConfigMutationVariables = CoreTypes.Exact<{
  config: CoreTypes.SaveConfigInput;
}>;


export type SaveConfigMutation = { saveConfig?: CoreTypes.Maybe<Pick<CoreTypes.ConfigData, 'data'>> };

export type SaveConsentMutationVariables = CoreTypes.Exact<{
  consent: CoreTypes.SaveConsentInput;
}>;


export type SaveConsentMutation = { saveConsent?: CoreTypes.Maybe<Pick<CoreTypes.Consent, 'dateTime' | 'email' | 'granted'>> };

export type SaveProfileMutationVariables = CoreTypes.Exact<{
  profile: CoreTypes.SaveProfileInput;
}>;


export type SaveProfileMutation = { saveProfile?: CoreTypes.Maybe<Pick<CoreTypes.Profile, 'name'>> };

export type StoreAccountSourceMutationVariables = CoreTypes.Exact<{
  source: CoreTypes.Scalars['String'];
}>;


export type StoreAccountSourceMutation = Pick<CoreTypes.Mutation, 'storeAccountSource'>;

export type VerifyEmailMutationVariables = CoreTypes.Exact<{
  userId: CoreTypes.Scalars['String'];
}>;


export type VerifyEmailMutation = Pick<CoreTypes.Mutation, 'verifyEmail'>;


export const AddressDocument = gql`
    query address($id: UUID!) {
  address(id: $id) {
    id
    type
    name
    name2
    ico
    dic
    street
    city
    zip
    country
    email
    phonePrefix
    phone
    forInvoicing
  }
}
    `;

/**
 * __useAddressQuery__
 *
 * To run a query within a React component, call `useAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.AddressQuery, CoreTypes.AddressQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.AddressQuery, CoreTypes.AddressQueryVariables>(AddressDocument, baseOptions);
      }
export function useAddressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.AddressQuery, CoreTypes.AddressQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.AddressQuery, CoreTypes.AddressQueryVariables>(AddressDocument, baseOptions);
        }
export type AddressQueryHookResult = ReturnType<typeof useAddressQuery>;
export type AddressLazyQueryHookResult = ReturnType<typeof useAddressLazyQuery>;
export type AddressQueryResult = ApolloReactCommon.QueryResult<CoreTypes.AddressQuery, CoreTypes.AddressQueryVariables>;
export const AddressesDocument = gql`
    query addresses($first: Int, $offset: Int) {
  addresses(first: $first, offset: $offset) {
    id
    type
    name
    name2
    ico
    dic
    street
    city
    zip
    country
    email
    phonePrefix
    phone
    forInvoicing
  }
}
    `;

/**
 * __useAddressesQuery__
 *
 * To run a query within a React component, call `useAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAddressesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.AddressesQuery, CoreTypes.AddressesQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.AddressesQuery, CoreTypes.AddressesQueryVariables>(AddressesDocument, baseOptions);
      }
export function useAddressesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.AddressesQuery, CoreTypes.AddressesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.AddressesQuery, CoreTypes.AddressesQueryVariables>(AddressesDocument, baseOptions);
        }
export type AddressesQueryHookResult = ReturnType<typeof useAddressesQuery>;
export type AddressesLazyQueryHookResult = ReturnType<typeof useAddressesLazyQuery>;
export type AddressesQueryResult = ApolloReactCommon.QueryResult<CoreTypes.AddressesQuery, CoreTypes.AddressesQueryVariables>;
export const BanksDocument = gql`
    query banks {
  banks {
    code
    name
  }
}
    `;

/**
 * __useBanksQuery__
 *
 * To run a query within a React component, call `useBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBanksQuery({
 *   variables: {
 *   },
 * });
 */
export function useBanksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.BanksQuery, CoreTypes.BanksQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.BanksQuery, CoreTypes.BanksQueryVariables>(BanksDocument, baseOptions);
      }
export function useBanksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.BanksQuery, CoreTypes.BanksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.BanksQuery, CoreTypes.BanksQueryVariables>(BanksDocument, baseOptions);
        }
export type BanksQueryHookResult = ReturnType<typeof useBanksQuery>;
export type BanksLazyQueryHookResult = ReturnType<typeof useBanksLazyQuery>;
export type BanksQueryResult = ApolloReactCommon.QueryResult<CoreTypes.BanksQuery, CoreTypes.BanksQueryVariables>;
export const CaseDocument = gql`
    query case($id: UUID!) {
  case(id: $id) {
    cashOnDelivery {
      account {
        bankCode
        number
        iban
        swift
      }
      cashOnDeliveryValue
      variableSymbol
    }
    doNotTip
    fragile
    id
    items {
      size
      weight
      parcelNumber
      parcelValue
    }
    language
    price {
      anotherCountry
      basePrice
      cashOnDelivery
      courierDelivery
      courierPickup
      extraInsurance
      total
    }
    recipient {
      city
      companyName
      country
      dic
      email
      ico
      name
      note
      phone
      phonePrefix
      postalCode
      street
      type
    }
    sendHow
    sendWhen
    sender {
      city
      cityInvoice
      country
      countryInvoice
      dic
      dicInvoice
      email
      ico
      icoInvoice
      name
      nameInvoice
      note
      phone
      phonePrefix
      postalCode
      postalCodeInvoice
      street
      streetInvoice
      type
      typeInvoice
    }
    status
    tracking
    utm {
      campaign
      content
      medium
      source
    }
    destAddress {
      id
      type
      name
      street
      city
      postalCode
      country
    }
    srcPudoId
  }
}
    `;

/**
 * __useCaseQuery__
 *
 * To run a query within a React component, call `useCaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.CaseQuery, CoreTypes.CaseQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.CaseQuery, CoreTypes.CaseQueryVariables>(CaseDocument, baseOptions);
      }
export function useCaseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.CaseQuery, CoreTypes.CaseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.CaseQuery, CoreTypes.CaseQueryVariables>(CaseDocument, baseOptions);
        }
export type CaseQueryHookResult = ReturnType<typeof useCaseQuery>;
export type CaseLazyQueryHookResult = ReturnType<typeof useCaseLazyQuery>;
export type CaseQueryResult = ApolloReactCommon.QueryResult<CoreTypes.CaseQuery, CoreTypes.CaseQueryVariables>;
export const CasesDocument = gql`
    query cases($offset: Int, $limit: Int) {
  cases(offset: $offset, limit: $limit) {
    count
    cases {
      id
      recipient {
        name
        street
        city
        postalCode
        country
      }
      sender {
        name
        street
        city
        postalCode
        country
      }
      sendHow
      sendWhen
      status
      doNotTip
      fragile
      price {
        anotherCountry
        basePrice
        cashOnDelivery
        courierPickup
        courierDelivery
        extraInsurance
        total
      }
      language
      items {
        size
        weight
        parcelNumber
        parcelValue
      }
      destAddress {
        id
        type
        name
        street
        city
        postalCode
        country
      }
      srcPudoId
    }
  }
}
    `;

/**
 * __useCasesQuery__
 *
 * To run a query within a React component, call `useCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCasesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCasesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.CasesQuery, CoreTypes.CasesQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.CasesQuery, CoreTypes.CasesQueryVariables>(CasesDocument, baseOptions);
      }
export function useCasesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.CasesQuery, CoreTypes.CasesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.CasesQuery, CoreTypes.CasesQueryVariables>(CasesDocument, baseOptions);
        }
export type CasesQueryHookResult = ReturnType<typeof useCasesQuery>;
export type CasesLazyQueryHookResult = ReturnType<typeof useCasesLazyQuery>;
export type CasesQueryResult = ApolloReactCommon.QueryResult<CoreTypes.CasesQuery, CoreTypes.CasesQueryVariables>;
export const ConfigDocument = gql`
    query config {
  config {
    data
  }
}
    `;

/**
 * __useConfigQuery__
 *
 * To run a query within a React component, call `useConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.ConfigQuery, CoreTypes.ConfigQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.ConfigQuery, CoreTypes.ConfigQueryVariables>(ConfigDocument, baseOptions);
      }
export function useConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.ConfigQuery, CoreTypes.ConfigQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.ConfigQuery, CoreTypes.ConfigQueryVariables>(ConfigDocument, baseOptions);
        }
export type ConfigQueryHookResult = ReturnType<typeof useConfigQuery>;
export type ConfigLazyQueryHookResult = ReturnType<typeof useConfigLazyQuery>;
export type ConfigQueryResult = ApolloReactCommon.QueryResult<CoreTypes.ConfigQuery, CoreTypes.ConfigQueryVariables>;
export const ConsentDocument = gql`
    query consent {
  consent
}
    `;

/**
 * __useConsentQuery__
 *
 * To run a query within a React component, call `useConsentQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsentQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.ConsentQuery, CoreTypes.ConsentQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.ConsentQuery, CoreTypes.ConsentQueryVariables>(ConsentDocument, baseOptions);
      }
export function useConsentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.ConsentQuery, CoreTypes.ConsentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.ConsentQuery, CoreTypes.ConsentQueryVariables>(ConsentDocument, baseOptions);
        }
export type ConsentQueryHookResult = ReturnType<typeof useConsentQuery>;
export type ConsentLazyQueryHookResult = ReturnType<typeof useConsentLazyQuery>;
export type ConsentQueryResult = ApolloReactCommon.QueryResult<CoreTypes.ConsentQuery, CoreTypes.ConsentQueryVariables>;
export const CreateCaseDocument = gql`
    mutation createCase($data: CaseDataInput!) {
  createCase(data: $data) {
    cashOnDelivery {
      account {
        bankCode
        number
        iban
        swift
      }
      cashOnDeliveryValue
      variableSymbol
    }
    doNotTip
    fragile
    id
    items {
      size
      weight
      parcelNumber
      parcelValue
    }
    language
    price {
      anotherCountry
      basePrice
      cashOnDelivery
      courierPickup
      courierDelivery
      extraInsurance
      total
    }
    recipient {
      city
      companyName
      country
      dic
      email
      ico
      name
      note
      phone
      phonePrefix
      postalCode
      street
      type
    }
    sendHow
    sendWhen
    sender {
      city
      cityInvoice
      country
      countryInvoice
      dic
      dicInvoice
      email
      ico
      icoInvoice
      name
      nameInvoice
      note
      phone
      phonePrefix
      postalCode
      postalCodeInvoice
      street
      streetInvoice
      type
      typeInvoice
    }
    status
    tracking
    utm {
      campaign
      content
      medium
      source
    }
    destAddress {
      id
      type
      name
      street
      city
      postalCode
      country
    }
    srcPudoId
  }
}
    `;
export type CreateCaseMutationFn = ApolloReactCommon.MutationFunction<CoreTypes.CreateCaseMutation, CoreTypes.CreateCaseMutationVariables>;

/**
 * __useCreateCaseMutation__
 *
 * To run a mutation, you first call `useCreateCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCaseMutation, { data, loading, error }] = useCreateCaseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoreTypes.CreateCaseMutation, CoreTypes.CreateCaseMutationVariables>) {
        return ApolloReactHooks.useMutation<CoreTypes.CreateCaseMutation, CoreTypes.CreateCaseMutationVariables>(CreateCaseDocument, baseOptions);
      }
export type CreateCaseMutationHookResult = ReturnType<typeof useCreateCaseMutation>;
export type CreateCaseMutationResult = ApolloReactCommon.MutationResult<CoreTypes.CreateCaseMutation>;
export type CreateCaseMutationOptions = ApolloReactCommon.BaseMutationOptions<CoreTypes.CreateCaseMutation, CoreTypes.CreateCaseMutationVariables>;
export const CreatePaymentDocument = gql`
    mutation createPayment($redirectUrl: String!, $id: UUID!) {
  createPayment(redirectUrl: $redirectUrl, id: $id) {
    orderId
    redirectUri
  }
}
    `;
export type CreatePaymentMutationFn = ApolloReactCommon.MutationFunction<CoreTypes.CreatePaymentMutation, CoreTypes.CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      redirectUrl: // value for 'redirectUrl'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoreTypes.CreatePaymentMutation, CoreTypes.CreatePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CoreTypes.CreatePaymentMutation, CoreTypes.CreatePaymentMutationVariables>(CreatePaymentDocument, baseOptions);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = ApolloReactCommon.MutationResult<CoreTypes.CreatePaymentMutation>;
export type CreatePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CoreTypes.CreatePaymentMutation, CoreTypes.CreatePaymentMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation deleteAccount($reason: String!) {
  deleteAccount(reason: $reason)
}
    `;
export type DeleteAccountMutationFn = ApolloReactCommon.MutationFunction<CoreTypes.DeleteAccountMutation, CoreTypes.DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoreTypes.DeleteAccountMutation, CoreTypes.DeleteAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CoreTypes.DeleteAccountMutation, CoreTypes.DeleteAccountMutationVariables>(DeleteAccountDocument, baseOptions);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = ApolloReactCommon.MutationResult<CoreTypes.DeleteAccountMutation>;
export type DeleteAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CoreTypes.DeleteAccountMutation, CoreTypes.DeleteAccountMutationVariables>;
export const DeleteAddressDocument = gql`
    mutation deleteAddress($id: UUID!) {
  deleteAddress(id: $id)
}
    `;
export type DeleteAddressMutationFn = ApolloReactCommon.MutationFunction<CoreTypes.DeleteAddressMutation, CoreTypes.DeleteAddressMutationVariables>;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDeleteAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoreTypes.DeleteAddressMutation, CoreTypes.DeleteAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<CoreTypes.DeleteAddressMutation, CoreTypes.DeleteAddressMutationVariables>(DeleteAddressDocument, baseOptions);
      }
export type DeleteAddressMutationHookResult = ReturnType<typeof useDeleteAddressMutation>;
export type DeleteAddressMutationResult = ApolloReactCommon.MutationResult<CoreTypes.DeleteAddressMutation>;
export type DeleteAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<CoreTypes.DeleteAddressMutation, CoreTypes.DeleteAddressMutationVariables>;
export const PaymentDocument = gql`
    query payment($id: UUID!) {
  payment(id: $id) {
    orderId
    status
  }
}
    `;

/**
 * __usePaymentQuery__
 *
 * To run a query within a React component, call `usePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.PaymentQuery, CoreTypes.PaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.PaymentQuery, CoreTypes.PaymentQueryVariables>(PaymentDocument, baseOptions);
      }
export function usePaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.PaymentQuery, CoreTypes.PaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.PaymentQuery, CoreTypes.PaymentQueryVariables>(PaymentDocument, baseOptions);
        }
export type PaymentQueryHookResult = ReturnType<typeof usePaymentQuery>;
export type PaymentLazyQueryHookResult = ReturnType<typeof usePaymentLazyQuery>;
export type PaymentQueryResult = ApolloReactCommon.QueryResult<CoreTypes.PaymentQuery, CoreTypes.PaymentQueryVariables>;
export const PickupTimeDocument = gql`
    query pickupTime($code: String!) {
  pickupTime(code: $code) {
    fromHour
    toHour
  }
}
    `;

/**
 * __usePickupTimeQuery__
 *
 * To run a query within a React component, call `usePickupTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupTimeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function usePickupTimeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.PickupTimeQuery, CoreTypes.PickupTimeQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.PickupTimeQuery, CoreTypes.PickupTimeQueryVariables>(PickupTimeDocument, baseOptions);
      }
export function usePickupTimeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.PickupTimeQuery, CoreTypes.PickupTimeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.PickupTimeQuery, CoreTypes.PickupTimeQueryVariables>(PickupTimeDocument, baseOptions);
        }
export type PickupTimeQueryHookResult = ReturnType<typeof usePickupTimeQuery>;
export type PickupTimeLazyQueryHookResult = ReturnType<typeof usePickupTimeLazyQuery>;
export type PickupTimeQueryResult = ApolloReactCommon.QueryResult<CoreTypes.PickupTimeQuery, CoreTypes.PickupTimeQueryVariables>;
export const ProfileDocument = gql`
    query profile {
  profile {
    account {
      bankCode
      number
    }
    email
    emailVerified
    phone
    phonePrefix
    name
  }
}
    `;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.ProfileQuery, CoreTypes.ProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.ProfileQuery, CoreTypes.ProfileQueryVariables>(ProfileDocument, baseOptions);
      }
export function useProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.ProfileQuery, CoreTypes.ProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.ProfileQuery, CoreTypes.ProfileQueryVariables>(ProfileDocument, baseOptions);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = ApolloReactCommon.QueryResult<CoreTypes.ProfileQuery, CoreTypes.ProfileQueryVariables>;
export const ProfileWithAdressessDocument = gql`
    query profileWithAdressess {
  profile {
    account {
      bankCode
      number
    }
    email
    emailVerified
    phone
    phonePrefix
    name
  }
  addresses(first: 99) {
    id
    type
    name
    name2
    ico
    dic
    street
    city
    zip
    country
    email
    phonePrefix
    phone
    forInvoicing
  }
}
    `;

/**
 * __useProfileWithAdressessQuery__
 *
 * To run a query within a React component, call `useProfileWithAdressessQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileWithAdressessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileWithAdressessQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileWithAdressessQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.ProfileWithAdressessQuery, CoreTypes.ProfileWithAdressessQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.ProfileWithAdressessQuery, CoreTypes.ProfileWithAdressessQueryVariables>(ProfileWithAdressessDocument, baseOptions);
      }
export function useProfileWithAdressessLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.ProfileWithAdressessQuery, CoreTypes.ProfileWithAdressessQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.ProfileWithAdressessQuery, CoreTypes.ProfileWithAdressessQueryVariables>(ProfileWithAdressessDocument, baseOptions);
        }
export type ProfileWithAdressessQueryHookResult = ReturnType<typeof useProfileWithAdressessQuery>;
export type ProfileWithAdressessLazyQueryHookResult = ReturnType<typeof useProfileWithAdressessLazyQuery>;
export type ProfileWithAdressessQueryResult = ApolloReactCommon.QueryResult<CoreTypes.ProfileWithAdressessQuery, CoreTypes.ProfileWithAdressessQueryVariables>;
export const QrCodesDocument = gql`
    query qrCodes($id: UUID!) {
  qrCodes(id: $id)
}
    `;

/**
 * __useQrCodesQuery__
 *
 * To run a query within a React component, call `useQrCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQrCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQrCodesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQrCodesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.QrCodesQuery, CoreTypes.QrCodesQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.QrCodesQuery, CoreTypes.QrCodesQueryVariables>(QrCodesDocument, baseOptions);
      }
export function useQrCodesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.QrCodesQuery, CoreTypes.QrCodesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.QrCodesQuery, CoreTypes.QrCodesQueryVariables>(QrCodesDocument, baseOptions);
        }
export type QrCodesQueryHookResult = ReturnType<typeof useQrCodesQuery>;
export type QrCodesLazyQueryHookResult = ReturnType<typeof useQrCodesLazyQuery>;
export type QrCodesQueryResult = ApolloReactCommon.QueryResult<CoreTypes.QrCodesQuery, CoreTypes.QrCodesQueryVariables>;
export const RouteDocument = gql`
    query route($country: Country!, $zip: String!) {
  route(data: {country: $country, zip: $zip}) {
    depot
    mpsAllowed
    sort
  }
}
    `;

/**
 * __useRouteQuery__
 *
 * To run a query within a React component, call `useRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRouteQuery({
 *   variables: {
 *      country: // value for 'country'
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useRouteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoreTypes.RouteQuery, CoreTypes.RouteQueryVariables>) {
        return ApolloReactHooks.useQuery<CoreTypes.RouteQuery, CoreTypes.RouteQueryVariables>(RouteDocument, baseOptions);
      }
export function useRouteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoreTypes.RouteQuery, CoreTypes.RouteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoreTypes.RouteQuery, CoreTypes.RouteQueryVariables>(RouteDocument, baseOptions);
        }
export type RouteQueryHookResult = ReturnType<typeof useRouteQuery>;
export type RouteLazyQueryHookResult = ReturnType<typeof useRouteLazyQuery>;
export type RouteQueryResult = ApolloReactCommon.QueryResult<CoreTypes.RouteQuery, CoreTypes.RouteQueryVariables>;
export const SaveAddressDocument = gql`
    mutation saveAddress($address: SaveAddressInput!) {
  saveAddress(address: $address) {
    id
    type
    name
    name2
    ico
    dic
    street
    city
    zip
    country
    email
    phonePrefix
    phone
    forInvoicing
  }
}
    `;
export type SaveAddressMutationFn = ApolloReactCommon.MutationFunction<CoreTypes.SaveAddressMutation, CoreTypes.SaveAddressMutationVariables>;

/**
 * __useSaveAddressMutation__
 *
 * To run a mutation, you first call `useSaveAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAddressMutation, { data, loading, error }] = useSaveAddressMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useSaveAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoreTypes.SaveAddressMutation, CoreTypes.SaveAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<CoreTypes.SaveAddressMutation, CoreTypes.SaveAddressMutationVariables>(SaveAddressDocument, baseOptions);
      }
export type SaveAddressMutationHookResult = ReturnType<typeof useSaveAddressMutation>;
export type SaveAddressMutationResult = ApolloReactCommon.MutationResult<CoreTypes.SaveAddressMutation>;
export type SaveAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<CoreTypes.SaveAddressMutation, CoreTypes.SaveAddressMutationVariables>;
export const SaveConfigDocument = gql`
    mutation saveConfig($config: SaveConfigInput!) {
  saveConfig(config: $config) {
    data
  }
}
    `;
export type SaveConfigMutationFn = ApolloReactCommon.MutationFunction<CoreTypes.SaveConfigMutation, CoreTypes.SaveConfigMutationVariables>;

/**
 * __useSaveConfigMutation__
 *
 * To run a mutation, you first call `useSaveConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveConfigMutation, { data, loading, error }] = useSaveConfigMutation({
 *   variables: {
 *      config: // value for 'config'
 *   },
 * });
 */
export function useSaveConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoreTypes.SaveConfigMutation, CoreTypes.SaveConfigMutationVariables>) {
        return ApolloReactHooks.useMutation<CoreTypes.SaveConfigMutation, CoreTypes.SaveConfigMutationVariables>(SaveConfigDocument, baseOptions);
      }
export type SaveConfigMutationHookResult = ReturnType<typeof useSaveConfigMutation>;
export type SaveConfigMutationResult = ApolloReactCommon.MutationResult<CoreTypes.SaveConfigMutation>;
export type SaveConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<CoreTypes.SaveConfigMutation, CoreTypes.SaveConfigMutationVariables>;
export const SaveConsentDocument = gql`
    mutation saveConsent($consent: SaveConsentInput!) {
  saveConsent(consent: $consent) {
    dateTime
    email
    granted
  }
}
    `;
export type SaveConsentMutationFn = ApolloReactCommon.MutationFunction<CoreTypes.SaveConsentMutation, CoreTypes.SaveConsentMutationVariables>;

/**
 * __useSaveConsentMutation__
 *
 * To run a mutation, you first call `useSaveConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveConsentMutation, { data, loading, error }] = useSaveConsentMutation({
 *   variables: {
 *      consent: // value for 'consent'
 *   },
 * });
 */
export function useSaveConsentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoreTypes.SaveConsentMutation, CoreTypes.SaveConsentMutationVariables>) {
        return ApolloReactHooks.useMutation<CoreTypes.SaveConsentMutation, CoreTypes.SaveConsentMutationVariables>(SaveConsentDocument, baseOptions);
      }
export type SaveConsentMutationHookResult = ReturnType<typeof useSaveConsentMutation>;
export type SaveConsentMutationResult = ApolloReactCommon.MutationResult<CoreTypes.SaveConsentMutation>;
export type SaveConsentMutationOptions = ApolloReactCommon.BaseMutationOptions<CoreTypes.SaveConsentMutation, CoreTypes.SaveConsentMutationVariables>;
export const SaveProfileDocument = gql`
    mutation saveProfile($profile: SaveProfileInput!) {
  saveProfile(profile: $profile) {
    name
  }
}
    `;
export type SaveProfileMutationFn = ApolloReactCommon.MutationFunction<CoreTypes.SaveProfileMutation, CoreTypes.SaveProfileMutationVariables>;

/**
 * __useSaveProfileMutation__
 *
 * To run a mutation, you first call `useSaveProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProfileMutation, { data, loading, error }] = useSaveProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useSaveProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoreTypes.SaveProfileMutation, CoreTypes.SaveProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<CoreTypes.SaveProfileMutation, CoreTypes.SaveProfileMutationVariables>(SaveProfileDocument, baseOptions);
      }
export type SaveProfileMutationHookResult = ReturnType<typeof useSaveProfileMutation>;
export type SaveProfileMutationResult = ApolloReactCommon.MutationResult<CoreTypes.SaveProfileMutation>;
export type SaveProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<CoreTypes.SaveProfileMutation, CoreTypes.SaveProfileMutationVariables>;
export const StoreAccountSourceDocument = gql`
    mutation storeAccountSource($source: String!) {
  storeAccountSource(source: $source)
}
    `;
export type StoreAccountSourceMutationFn = ApolloReactCommon.MutationFunction<CoreTypes.StoreAccountSourceMutation, CoreTypes.StoreAccountSourceMutationVariables>;

/**
 * __useStoreAccountSourceMutation__
 *
 * To run a mutation, you first call `useStoreAccountSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreAccountSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeAccountSourceMutation, { data, loading, error }] = useStoreAccountSourceMutation({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useStoreAccountSourceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoreTypes.StoreAccountSourceMutation, CoreTypes.StoreAccountSourceMutationVariables>) {
        return ApolloReactHooks.useMutation<CoreTypes.StoreAccountSourceMutation, CoreTypes.StoreAccountSourceMutationVariables>(StoreAccountSourceDocument, baseOptions);
      }
export type StoreAccountSourceMutationHookResult = ReturnType<typeof useStoreAccountSourceMutation>;
export type StoreAccountSourceMutationResult = ApolloReactCommon.MutationResult<CoreTypes.StoreAccountSourceMutation>;
export type StoreAccountSourceMutationOptions = ApolloReactCommon.BaseMutationOptions<CoreTypes.StoreAccountSourceMutation, CoreTypes.StoreAccountSourceMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($userId: String!) {
  verifyEmail(userId: $userId)
}
    `;
export type VerifyEmailMutationFn = ApolloReactCommon.MutationFunction<CoreTypes.VerifyEmailMutation, CoreTypes.VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoreTypes.VerifyEmailMutation, CoreTypes.VerifyEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<CoreTypes.VerifyEmailMutation, CoreTypes.VerifyEmailMutationVariables>(VerifyEmailDocument, baseOptions);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = ApolloReactCommon.MutationResult<CoreTypes.VerifyEmailMutation>;
export type VerifyEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<CoreTypes.VerifyEmailMutation, CoreTypes.VerifyEmailMutationVariables>;