export const deArray = <T>(item: T | T[]) =>
  Array.isArray(item) ? item[0] : item;

export const toArray = <T>(item?: T | T[] | null) =>
  item === null || item === undefined
    ? []
    : Array.isArray(item)
    ? item
    : [item];

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export const groupBy = <L, K, V = L>(
  iterable: Iterable<L>,
  keyGetter: (entry: L) => K,
  //@ts-ignore
  valueGetter: (entry: L) => V = (item) => item,
) => {
  const map = new Map<K, Array<V>>();

  for (const item of iterable) {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [valueGetter(item)]);
    } else {
      collection.push(valueGetter(item));
    }
  }
  return map;
};

/** replaces item in array (with mutating array); returns indexes of replaced items */
export const replaceP = <T>(
  list: Array<T>,
  predicate: (input: T) => boolean,
  replacer?: (old: T, index: number) => T,
) => {
  const indexesToDelete = list
    .map((x, i) => ({ i, toDel: predicate(x) }))
    .filter((x) => x.toDel)
    .map((x) => x.i);
  indexesToDelete.forEach((i) =>
    replacer ? list.splice(i, 1, replacer(list[i], i)) : list.splice(i, 1),
  );
  return indexesToDelete;
};

/** replaces items in array and returns a new array. If nothing is replaced, then the original array is returned */
export const replace = <T>(
  list: Array<T>,
  predicate: (input: T) => boolean,
  replacer?: (old: T, index: number) => T,
) => {
  const clone = [...list];
  const replacedIndexes = replaceP(clone, predicate, replacer);
  return replacedIndexes.length ? clone : list;
};
