import csDateFns from 'date-fns/locale/cs';
import enDateFns from 'date-fns/locale/en';
import getConfig from 'next/config';

import { ConfigType, Environment, Language } from './types';

const fnsLocales = { cs: csDateFns, en: enDateFns };

const {
  publicRuntimeConfig: {
    environment,
    internalServerBase,
    apiEndpoint,
    firebaseConfig,
  },
} = getConfig();

//todo: rename to appConfig
export const config: ConfigType = {
  app: {
    defaultLanguage: Language.CS,
    defaultCountry: 'CZ',
    defaultGoogleApiCountry: 'cz',
    nodeJsTimezone: 'Europe/Amsterdam',
    environment: environment as Environment,
    googleSiteId: '',
    googleApiKey:
      environment === Environment.PRODUCTION
        ? 'AIzaSyDaOheSB36yLq7jYR3ITn-yYvK1Y7QGctc'
        : environment === Environment.DEV
        ? 'AIzaSyAUHNSmgdVpt-Lqk3JZOXAIW7Rl03wYyh0'
        : 'AIzaSyBrLb65mtwNgQg7jtK7VUV9aIs0XPw_0as', // Environment.LOCAL
    googleTagManagerId:
      environment === Environment.PRODUCTION ? 'GTM-W9C9687' : 'GTM-WQTVX82',
    parcelShopSearchApiUrl:
      'https://pickup.dpd.cz/api/get-parcel-shops-by-address',
    parcelShopSearchUrl: 'https://pickup.dpd.cz',
    parcelShopSearchUrlEn: 'https://pickup.dpd.cz/en',
    dateFnsLocale: fnsLocales.cs,
    firebaseConfig,
    accountOrderListSize: 3,
    accountOrderListBatchSize: 6,
    accountAddressListSize: 2,
    accountParcelShopsListSize: 9,
  },
  api: {
    apiEndpoint,
    graphqlLocalEndpoint: '/graphql',
    internalServerBase,
  },
  contactInfo: {
    email: 'test@test.test',
    phone: '+420 777 777 777',
  },
  emergency: {
    phone: '911',
  },
};
if (typeof window === 'undefined') {
  if (config.app.nodeJsTimezone) process.env.TZ = config.app.nodeJsTimezone;

  console.info(
    'TIMEZONE=',
    new Date().getTimezoneOffset(),
    config.app.nodeJsTimezone,
  );
}
