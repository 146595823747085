import { useFormik } from 'formik';
import { DependencyList, useEffect, useState } from 'react';

export const useEffectValidateOnInitForm = <T>(
  formik: ReturnType<typeof useFormik>,
  emptyFormValues: T,
  condition?: boolean,
  otherDependencies: DependencyList = [],
) => {
  const [emptyValues] = useState(emptyFormValues);
  useEffect(() => {
    if (condition || condition === undefined || condition === null) {
      formik.validateForm().then((errors) => {
        Object.entries(errors).forEach(([key]) => {
          if (emptyValues) {
            const orig = emptyValues[key];
            const current = formik.values[key];

            if (orig !== current) formik.setFieldTouched(key);
          } else formik.setFieldTouched(key);
        });
      });
    }
  }, [condition, emptyValues, ...otherDependencies]);
};
