import { Trans } from '@lingui/react';
import React from 'react';

export const inlineKeys = {
  password: {
    key: 'validation.password.info',
    trans: (
      <Trans id="validation.password.info">
        Heslo musí obsahovat alespoň 8 znaků, včetně velkého písmene, malého
        písmene a čísla.
      </Trans>
    ),
  },
  passwordConfirm: {
    key: 'validation.passwordConfirm.info',
    trans: (
      <Trans id="validation.passwordConfirm.info">
        Hesla se musí shodovat.
      </Trans>
    ),
  },
  registrationNotValidForThisEmail: {
    key: 'validation.registrationNotValidForThisEmail.info',
    trans: (
      <Trans id="validation.registrationNotValidForThisEmail.info">
        Zadaný email již je zaregistrován.
      </Trans>
    ),
  },
  loginNotValidForThisEmail: {
    key: 'validation.loginNotValidForThisEmail.info',
    trans: (
      <Trans id="validation.loginNotValidForThisEmail.info">
        Pro zadaný email není registrován žádný účet.
      </Trans>
    ),
  },

  terms: {
    key: 'validation.terms.info',
    trans: (
      <Trans id="validation.terms.info">
        Potvrďte prosím, že souhlasíte s našimi podmínkami.
      </Trans>
    ),
  },

  personalDataAgreement: {
    key: 'validation.userInfo.personalDataAgreement',
    trans: (
      <Trans id="validation.userInfo.personalDataAgreement">
        Potvrďte prosím, že souhlasíte se zpracováním osobních údajů.
      </Trans>
    ),
  },
  oAuthTerms: {
    key: 'validation.terms.oauth.info',
    trans: (
      <Trans id="validation.terms.oauth.info">
        Abyste mohli pokračovat, musíte souhlasit.
      </Trans>
    ),
  },
  phoneValidation: {
    key: 'validation.phone',
    trans: (
      <Trans id="validation.phone">
        Vyplňte prosím správné telefonní číslo.
      </Trans>
    ),
  },
  dateValidation: {
    key: 'validation.date',
    trans: (
      <Trans id="validation.date">
        Vyplňte prosím datum ve správném formátu.
      </Trans>
    ),
  },
  parcelValueValidation: {
    key: 'validation.parcelValue',
    trans: (
      <Trans id="validation.parcelValue">
        Zadejte prosím hodnotu zboží v cenovém rozpětí {`{minPrice}`}-
        {`{maxPrice}`}.
      </Trans>
    ),
  },
  cashOnDeliveryValidation: {
    key: 'validation.cashOnDelivery',
    trans: (
      <Trans id="validation.cashOnDelivery">
        Zadete prosím výši dobírky v cenovém rozpětí 10 Kč-50 000 Kč.
      </Trans>
    ),
  },
  cashOnDeliveryValidationSK: {
    key: 'validation.cashOnDeliverySK',
    trans: (
      <Trans id="validation.cashOnDeliverySK">
        Zadete prosím výši dobírky v cenovém rozpětí 1 EUR - 2000 EUR.
      </Trans>
    ),
  },
  accountValidation: {
    key: 'validation.account',
    trans: (
      <Trans id="validation.account">Vyplňte prosím správné číslo účtu.</Trans>
    ),
  },
  bankCodeValidation: {
    key: 'validation.bankCode',
    trans: <Trans id="validation.bankCode">Vyberte prosím kód banky.</Trans>,
  },
  ibanValidation: {
    key: 'validation.iban',
    trans: <Trans id="validation.iban">IBAN musí být ve správném tvaru.</Trans>,
  },
  ibanCountryValidation: {
    key: 'validation.ibanCountry',
    trans: (
      <Trans id="validation.ibanCountry">
        IBAN musí začínat na: {`{countries}`}.
      </Trans>
    ),
  },
  swiftValidation: {
    key: 'validation.swift',
    trans: (
      <Trans id="validation.swift">SWIFT musí být ve správném tvaru.</Trans>
    ),
  },
  variableSymbolValidation: {
    key: 'validation.variableSymbol',
    trans: (
      <Trans id="validation.variableSymbol">
        Variabilní symbol se musí skládat z maximálně 10 číslic.
      </Trans>
    ),
  },
  constantSymbolValidation: {
    key: 'validation.constantSymbol',
    trans: (
      <Trans id="validation.constantSymbol">
        Vyplňte prosím konstantní symbol ve správném formátu.
      </Trans>
    ),
  },
  streetWithNumberValidation: {
    key: 'validation.streetWithNumber',
    trans: (
      <Trans id="validation.streetWithNumber">
        Vyplňte prosím ulici včetně čísla popisného.
      </Trans>
    ),
  },
  postalCodeValidation: {
    key: 'validation.postalCode',
    trans: (
      <Trans id="validation.postalCode">
        Vyplňte prosím existující PSČ ve správném formátu.
      </Trans>
    ),
  },
  postalCodeValidationNoDestinationRoute: {
    key: 'validation.postalCodeDestinationRoute',
    trans: (
      <Trans id="validation.postalCodeDestinationRoute">
        Mrzí nás to, ale v této lokalitě zásilky nedoručujeme.
      </Trans>
    ),
  },
  postalCodeValidationNoSourceRoute: {
    key: 'validation.postalCodeSourceRoute',
    trans: (
      <Trans id="validation.postalCodeSourceRoute">
        Mrzí nás to, ale zadané PSČ není v naší přepravní síti. Vyplňte prosím
        jinou adresu odeslání.
      </Trans>
    ),
  },
  pickupPlaceMustBeChoosen: {
    key: 'validation.pickupPlaceMustBeChoosen',
    trans: (
      <Trans id="validation.pickupPlaceMustBeChoosen">
        Vyberte prosím pobočku/box kam odnesete zásilku.
      </Trans>
    ),
  },
  deliveryPlaceMustBeChoosen: {
    key: 'validation.deliveryPlaceMustBeChoosen',
    trans: (
      <Trans id="validation.deliveryPlaceMustBeChoosen">
        Vyberte prosím pobočku/box pro doručení.
      </Trans>
    ),
  },
};
