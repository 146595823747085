export const preventDefaultOnEnter = (keyEvent) => {
  const activeField = document.activeElement.getAttribute('name');

  if (
    activeField === 'street' &&
    (keyEvent.charCode || keyEvent.keyCode) === 13
  ) {
    keyEvent.preventDefault();
  }
};
