import { Country } from '@hp/core/shared';
import { CzechHolidays } from 'czech-holidays';
import { isHoliday as isSlovakHoliday } from 'slovak-holidays';

export const isHoliday = (date: Date, country: Country) => {
  switch (country) {
    case Country.CZ:
      const [year, month, day] = [
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
      ];
      return CzechHolidays(year).some(({ d, m }) => m === month && d === day);
    case Country.SK:
      return isSlovakHoliday(date);
    default:
      throw new Error(
        `Unknown holidays - country "${country}" is not supported`,
      );
  }
};
