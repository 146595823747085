import {
  Country,
  DynamicConfigRoot,
  EstimateDeliveryMethod,
  getSpecificCfg,
} from '@hp/core/shared';
import { I18n } from '@lingui/core';
import { Trans } from '@lingui/react';
import React from 'react';

import { upcomingWorkdays } from './upcomingWorkdays';

export const getDeliveryDate = (
  pickupDate: Date,
  pickupCountry: Country,
  deliveryCountry: Country,
  config: DynamicConfigRoot,
  i18n: I18n,
) => {
  const { days, method } = getSpecificCfg(
    config.constraints.estimateDelivery,
    deliveryCountry,
  );

  if (method === EstimateDeliveryMethod.COMPUTE) {
    const delta = days as number; //we know, it is must be only one number;
    const a = Array.from(
      upcomingWorkdays(pickupDate, i18n, 1, pickupCountry),
    )[0];
    const b = Array.from(
      upcomingWorkdays(pickupDate, i18n, delta, deliveryCountry),
    ).reverse()[0];
    return (a.date < b.date ? b : a).label;
  }

  if (typeof days === 'number') {
    if (days === 1) {
      return (
        <Trans id="common.deliveryEstimate.1">
          obvykle následující pracovní den
        </Trans>
      );
    }
    return (
      <Trans id="common.deliveryEstimate.x">
        obvykle do {days} pracovních dní
      </Trans>
    );
  }
  return (
    <Trans id="common.deliveryEstimate.x-y">
      obvykle během {days[0]}-{days[1]} pracovních dní
    </Trans>
  );
};
