import { useFormik } from 'formik';
import { useEffect } from 'react';

/** optimalized useEffect for saving form with throttling.*/
export const useEffectSaveForm = <T>(
  formik: ReturnType<typeof useFormik>,
  saveFormData: (value: T) => void,
  shallowCompare = false,
) => {
  useEffect(
    () => {
      const h = setTimeout(() => {
        return saveFormData(formik.values as T);
      }, 500);
      return () => clearTimeout(h);
    } /* we use this to shallow compare all values rather than compare object themself*/,
    shallowCompare ? Object.values(formik.values) : [formik.values],
  );
};
