import { Language } from '@hp/core/shared';

const getCommaSeparator = (lang: Language) => {
  switch (lang) {
    case Language.CS:
      return ',';
    default:
      return '.';
  }
};

export const formatNumber = (num: number, language: Language) => {
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return parts.join(getCommaSeparator(language));
};
