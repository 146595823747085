import {
  DynamicConfigRoot,
  getSpecificCfg,
  ParcelSpecs,
  PricingListType,
} from '../runtimeConfig/config';
import {
  CaseDataInput,
  ParcelPartDescriptionInput,
  PickupType,
  PriceInput,
} from './../graphql/generated/types';
import { clamp } from './clamp';

export const computeBaseItemPrice = (
  item: ParcelPartDescriptionInput,
  priceList: PricingListType,
  parcelSpecification: ParcelSpecs,
) => {
  const basePrice = priceList.priceBase[item.size];
  if (typeof basePrice === 'number') return basePrice;
  const { minWeight, maxWeight, ranges } = parcelSpecification;
  if (!ranges)
    throw new Error(
      'Invalid price configuration, when price is range, range must be specified!',
    );
  const weight = clamp(item.weight, minWeight, maxWeight - 1);
  const index = ranges.findIndex((r) => r[0] <= weight && weight <= r[1]);
  if (index === -1)
    throw new Error('Item has not specified out-of-range weight !');
  return basePrice[index];
};

export const computeInputPrice = (
  input: Omit<CaseDataInput, 'price' | 'language'>,
  config: DynamicConfigRoot,
) => {
  const { items, sendHow, cashOnDelivery, recipient, destAddress } = input;

  const price: PriceInput = {
    basePrice: 0,
    extraInsurance: 0,
    cashOnDelivery: 0,
    courierPickup: 0,
    courierDelivery: 0,
    total: 0,
    anotherCountry: 0,
  };

  const countryPriceList = getSpecificCfg(config.priceList, recipient.country);

  price.courierPickup =
    sendHow === PickupType.COURIER ? countryPriceList.courierPickUpShipment : 0;

  price.courierDelivery = destAddress?.id
    ? 0
    : countryPriceList.courierDeliveryShipment;

  price.basePrice += items.reduce(
    (total, item) =>
      total +
      computeBaseItemPrice(
        item,
        countryPriceList,
        getSpecificCfg(config.parcelSpecifications, recipient.country)[
          item.size
        ],
      ),
    0,
  );

  price.extraInsurance = items.reduce(
    (total, item) =>
      total +
      Math.round(
        (item.parcelValue ?? 0) * countryPriceList.extraInsuranceRatio,
      ),
    0,
  );

  if (cashOnDelivery) price.cashOnDelivery = countryPriceList.cashOnDelivery;

  price.total = Object.values(price).reduce((total, value) => total + value, 0);
  return price;
};
