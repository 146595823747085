import { Country, Language } from '@hp/core/shared';
import { I18n } from '@lingui/core';

import { upcomingWorkdays } from './upcomingWorkdays';

const pickupDaysOptionsCount = 7;

export const pickupDateOptions = (
  pickupCountry: Country,
  i18n: I18n,
  withDayName = false,
  language?: Language,
) => {
  return Array.from(
    upcomingWorkdays(
      new Date(),
      i18n,
      pickupDaysOptionsCount,
      pickupCountry,
      withDayName,
      language,
    ),
  );
};
