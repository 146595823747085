import { useApolloClient } from '@apollo/react-hooks';
import { Country, PickupType } from '@hp/core/shared';
import { useYup } from '@hp/form';
import { inlineKeys, useI18n } from '@hp/locale';
// TODO: move RouteDocument to '@hp/core/shared'
import { RouteDocument } from '@hp/order/src/graphql';
import { PickupFormType } from '@hp/order/src/types';

const postalCodesCache: Record<string, boolean> = {};

let validatingCounter = 0;

/** returns TRUE when validation has sense */
export const isPostalCodeValidable = (value, country) => {
  if (!value || value.length < 3) {
    return false;
  }

  if (country === Country.CZ && value.length < 5) {
    return false;
  }

  if (country === Country.SK && value.length < 5) {
    return false;
  }

  return true;
};

//see: https://www.html5pattern.com/Postal_Codes
const regexs = {
  [Country.CZ]: /^[0-9]{5}$/,
  [Country.SK]: /^[0-9]{5}$/,
  [Country.DE]: /^[0-9]{5}$/,
  [Country.AT]: /^[0-9]{4}$/,
  [Country.NL]: /^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/,
};

/** prevalidation = optimalization, no to call graphql everytime */
const isPostalCodeSimple = (value: string, country: Country) => {
  if (!value) {
    return false;
  }

  const regex = regexs[country] ?? /^\d{0,9}$/;
  const valid = !!value.match(regex);

  return valid;
};

const validatePostalCode = (value, country, client) => {
  if (!isPostalCodeSimple(value, country)) {
    return false;
  }

  const key = country + value;
  const cachedResult = postalCodesCache[key];

  if (cachedResult === false || cachedResult === true) {
    return cachedResult;
  }

  const currentValidatingIndex = ++validatingCounter;

  return client
    .query({
      query: RouteDocument,
      variables: {
        country: country,
        zip: value,
      },
      fetchPolicy: 'cache-first',
    })
    .then(() => {
      postalCodesCache[key] = true;

      return true;
    })
    .catch(() => {
      postalCodesCache[key] = false;
      //there is a new validation request on the fly, so we returns true.
      if (validatingCounter !== currentValidatingIndex) {
        return true;
      }

      return false;
    });
};

/** specialized validations for Orders & Parcels */
export const useYupSharedOrderValidations = (
  yup: ReturnType<typeof useYup>,
) => {
  const i18n = useI18n();
  const apolloClient = useApolloClient();

  return {
    pickup: yup.mixed<PickupType>().oneOf(Object.values(PickupType)).required(),
    pickupForm: yup
      .mixed<PickupFormType>()
      .oneOf(Object.values(PickupFormType))
      .required(),
    postalCodeSimply: (country: Country, name: string) =>
      yup
        .string()
        .test(name, i18n._(inlineKeys.postalCodeValidation.key), (value) =>
          isPostalCodeSimple(value, country),
        ),
    postalCodeSimplyByCountryField: (name: string, countryField: string) =>
      yup
        .string()
        .required()
        .test(name, i18n._(inlineKeys.postalCodeValidation.key), function (
          // beware, don't use arrow function here otherwise you would not the reference to `this` object
          value,
        ) {
          return isPostalCodeSimple(value, this.resolve(yup.ref(countryField)));
        }),
    postalCode: (country: Country, name: string, forPickup: boolean) =>
      yup
        .string()
        .test(
          name,
          forPickup
            ? i18n._(inlineKeys.postalCodeValidationNoSourceRoute.key)
            : i18n._(inlineKeys.postalCodeValidationNoDestinationRoute.key),
          (value) => validatePostalCode(value, country, apolloClient),
        ),
  };
};
