import { DynamicConfigRoot } from '@hp/core/shared';
import React, { useContext } from 'react';

const DyncCfgContext = React.createContext<DynCfgContextProps>(null);

type DynCfgContextProps = {
  config: DynamicConfigRoot;
};

export const DyncCfgProvider: React.FC<DynCfgContextProps> = ({
  children,
  ...props
}) => {
  return (
    <DyncCfgContext.Provider value={{ ...props }}>
      {children}
    </DyncCfgContext.Provider>
  );
};

export const useConfig = () => {
  return useContext(DyncCfgContext);
};
