import { Country, Language } from '@hp/core/shared';
import { formatDate } from '@hp/utils/src/formatDate';
import { I18n } from '@lingui/core';
import { addDays, getDay, isWeekend } from 'date-fns';

import { getDayName } from './getDayName';
import { isHoliday } from './isHoliday';

export const upcomingWorkdays = function* (
  refDate: Date,
  i18n: I18n,
  numberOfDays = 7,
  country: Country,
  withDayName?: boolean,
  language?: Language,
) {
  let i = 1;
  let total = 0;
  while (true) {
    const newDate = addDays(refDate, i++);

    if (!isWeekend(newDate) && !isHoliday(newDate, country)) {
      if (total++ === numberOfDays) break;

      yield {
        value: formatDate(newDate, 'YYYY-MM-DD'),
        label: withDayName
          ? `${getDayName(getDay(newDate), language, i18n, true)} ${formatDate(
              newDate,
              'DD.MM.YYYY',
            )}`
          : formatDate(newDate, 'DD.MM.YYYY'),
        date: newDate,
      };
    }
  }
};
