import { config, Environment } from '@hp/config';
import { delay } from '@hp/core/shared';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

export function useFetchedData<T>(url?: string, options?: { skip?: boolean }) {
  const [data, setData] = useState<T>(null);
  const skip = !url || options?.skip;
  const [loading, setLoading] = useState(!skip);
  const [error, setError] = useState<any>(null);

  const fetchData = async (dataUrl = url) => {
    try {
      setLoading(true);
      const result = await axios(dataUrl);
      if (
        config.app.environment === Environment.DEV ||
        config.app.environment === Environment.LOCAL
      ) {
        console.log('fetching ' + dataUrl);
        //in dev we simulate delayed responses
        const delayMs = Math.exp(Math.random() * 7 - 1);
        console.log(`delaying in dev-mode ${delayMs}ms`);
        await delay(delayMs);
      }

      setData(result?.data);
      setError(null);
    } catch (e) {
      setError(e);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (skip) return;
    fetchData();
  }, [skip]);
  const fetchDataCb = useCallback(fetchData, [url]);
  return { data, loading, error, fetchData: fetchDataCb };
}
