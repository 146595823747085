import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import React from 'react';

const Optional: React.FC = ({ children }) => (
  <span>
    {children}&nbsp;(<Trans id="common.field.optional">nepovinné</Trans>)
  </span>
);

export const fields = {
  firstName: <Trans id="common.field.firstName">Jméno</Trans>,
  lastName: <Trans id="common.field.lastName">Příjmení</Trans>,
  phoneNumber: <Trans id="common.field.phoneNumber">Telefon</Trans>,
  invoiceToAnotherAddress: (
    <Trans id="common.field.invoiceToAnotherAddress">
      Vystavit fakturu s jinými údaji
    </Trans>
  ),
  ico: <Trans id="common.field.ico">IČO</Trans>,
  icoNotRequired: (
    <Optional>
      <Trans id="common.field.ico">IČO</Trans>
    </Optional>
  ),
  dic: <Trans id="common.field.dic">DIČ</Trans>,
  dicNotRequired: (
    <Optional>
      <Trans id="common.field.dic">DIČ</Trans>
    </Optional>
  ),
  accountNumberCZ: (
    <Trans id="common.field.accountNumberCZ">Číslo účtu v ČR</Trans>
  ),
  bankCode: <Trans id="common.field.bankCode">Banka</Trans>,
  iban: <Trans id="common.field.iban">IBAN</Trans>,
  swift: <Trans id="common.field.swift">Swift</Trans>,
  addPaymentDetails: (
    <Trans id="common.field.addPaymentDetails">Uvést variabilní symbol</Trans>
  ),
  variableSymbol: (
    <Trans id="common.field.variableSymbol">Variabilní symbol</Trans>
  ),
  constantSymbol: <Trans id="common.field.constantSymbol">Konst. symbol</Trans>,
  createAccount: (
    <Trans id="common.field.createAccountCheckox">Chci účet u DPD</Trans>
  ),
};

const address = {
  labels: {
    street: <Trans id="common.address.street">Ulice a číslo popisné</Trans>,
    postalCode: <Trans id="common.address.postalCode">PSČ</Trans>,
    city: <Trans id="common.address.city">Město / Obec</Trans>,
  },
};

export const labels = {
  email: <Trans id="common.label.email">E-mail</Trans>,
  emailNotRequired: (
    <Optional>
      <Trans id="common.label.email">E-mail</Trans>
    </Optional>
  ),
  setPassword: <Trans id="common.label.setPassword">Nastavit heslo</Trans>,
  password: <Trans id="common.label.password">Heslo</Trans>,
  passwordOld: <Trans id="common.label.passwordOld">Stávající heslo</Trans>,
  passwordNew: <Trans id="common.label.passwordNew">Nové heslo</Trans>,
  passwordNewConfirm: (
    <Trans id="common.label.passwordNewConfirm">Potvrzení nového hesla</Trans>
  ),
  passwordConfirmAction: (
    <Trans id="common.label.passwordConfirmAction">Potvrdit heslem</Trans>
  ),
  recipient: {
    fullName: (
      <Trans id="common.label.recipient.fullName">Jméno a příjmení</Trans>
    ),
    companyName: (
      <Trans id="common.label.recipient.companyName">Název firmy</Trans>
    ),
    contactPersonNotRequired: (
      <Optional>
        <Trans id="common.label.contactPerson">Kontaktní osoba</Trans>
      </Optional>
    ),
    noteNotRequired: (
      <Optional>
        <Trans id="common.label.recipient.note">Poznámka k doručení</Trans>
      </Optional>
    ),
    deliveryCountry: (
      <Trans id="common.label.recipient.deliveryCountry">Země doručení</Trans>
    ),
    ...address.labels,
  },
  sender: {
    email: <Trans id="common.label.sender.email">E-mail</Trans>,
    fullName: <Trans id="common.label.sender.fullName">Jméno a příjmení</Trans>,
    noteNotRequired: (
      <Optional>
        <Trans id="common.label.sender.note">Poznámka k vyzvednutí</Trans>
      </Optional>
    ),
    pickupCountry: (
      <Trans id="common.label.recipient.pickupCountry">Země vyzvednutí</Trans>
    ),
    invoiceName: (
      <Trans id="common.label.sender.invoiceName">
        Jméno a příjmení / Název firmy
      </Trans>
    ),
    ...address.labels,
  },
  country: <Trans id="common.address.country">Země</Trans>,
  howToSend: {
    sendToCountry: <Trans id="common.label.sendToCountry">Země doručení</Trans>,
    sendWhen: <Trans id="common.label.sendWhen">Datum vyzvednutí</Trans>,
    withSourceCountry: {
      sendFromCountry: (
        <Trans id="common.label.withSourceCountry.sendFromCountry">
          Odkud?
        </Trans>
      ),
      sendToCountry: (
        <Trans id="common.label.withSourceCountry.sendToCountry">Kam?</Trans>
      ),
    },
  },
  dimension: {
    width: <Trans id="common.label.dimension.width">Šířka</Trans>,
    height: <Trans id="common.label.dimension.height">Výška</Trans>,
    length: <Trans id="common.label.dimension.length">Délka</Trans>,
  },
  switchLanguage: <Trans id="common.label.switchLanguage">Akuální jazyk</Trans>,
  deleteAccount: {
    otherReason: (
      <Trans id="common.label.deleteAccount.otherReason">
        Budeme rádi za jiný konkrétní důvod.
      </Trans>
    ),
    confirmWithPassword: (
      <Trans id="common.label.deleteAccount.confirmWithPassword">
        Potvrdit heslem
      </Trans>
    ),
  },
};

export const placeholders = {
  email: t('common.placeholder.email')`Vložte svůj e-mail`,
  fullName: t('common.placeholder.fullName')`Napište celé jméno`,
  comment: t(
    'common.placeholder.textarea.comment',
  )`Váš komentář (volitelný), maximálně 50 znaků`,
  search: t('common.placeholder.search')`Vyhledat`,
  searchUser: t('common.placeholder.searchUser')`Zadejte e-mail uživatele`,
  searchParcelShop: t('common.placeholder.searchParcelShop')`Město, Ulice, PSČ`,
  parcelValue: t('common.placeholder.parcelValue')`Hodnota zboží`,
};

export const messages = {
  sectionInProgress: (
    <Trans id="common.messages.sectionInProgress">
      Chcete-li pokračovat, musíte změny použít nebo zrušit
    </Trans>
  ),
};
