import { Country } from '@hp/core/shared';

export const formatPostalCode = (postalCode: string, country: Country) => {
  if (country === Country.CZ || country === Country.SK)
    return postalCode?.replace(/(\d\d\d)(\d)/g, '$1 $2');

  return postalCode;
};

export const formatPostalCodeSimple = (postalCode: string) => {
  return postalCode?.replace(/(\d\d\d)(\d)/g, '$1 $2');
};

export const unformatPostalCode = (formValue: string) =>
  formValue?.replace(/\s/g, '');
