import { AddressType, Country, ParcelSize, PickupType } from '@hp/core/shared';
import { RoutesType } from '@hp/seo';
import { DayOfTheWeek } from '@hp/utils';

export type AddressCommonFormFields = {
  name: string;
  street: string;
  city: string;
  postalCode: string;
  email: string;
};

export type ParcelPartDescription = {
  size: ParcelSize;
  weight?: number | null;
};
export type ParcelShopFilter = {
  limit?: number;
  type?: 'locker' | 'parcelShop';
  address?: string;
  distance?: number;
  enabled?: boolean;
  onlineCodAllowed?: boolean;
  dropOffWithoutLabel?: boolean;
  codAllowed?: boolean;
  prepaidAllowed?: boolean;
  cardPaymentAllowed?: boolean;
  openInLateAfternoon?: boolean;
  openOnWeekends?: boolean;
} & Partial<Coordinates>;

export type ParcelShopFilterWithFormSpecs = {
  filter?: ParcelShopFilter;
  addressText?: string;
  realTypedText?: string;
};

/** type for usage in form, where is switch between this 2 types */
export enum PickupFormType {
  COURIER = 'COURIER',
  PARCELSHOP_OR_BOX = 'PARCELSHOP_OR_BOX',
}

export enum LockerProviderType {
  DPD_LOCKER = 'DPDLocker',
  ALZABOX = 'AlzaBox',
}

export type HowToSendFormFields = {
  sendFromCountry: Country;
  sendToCountry: Country;
  // sendHow: PickupType;
  sendWhen?: string;
  /**@deprecated */
  selectedParcelShopId?: string;
  /**@deprecated */
  selectedParcelShopName?: string;
  /**@deprecated */
  selectedParcelShopAddress?: string;
  /**@deprecated */
  selectedParcelShopCodAllowed?: string;

  pickupFormType: PickupFormType;
  pickupType: PickupType;

  pickupId?: string;
  deliveryFormType: PickupFormType;
  deliveryType: PickupType;
  deliveryId?: string;
  pickupLockerProvider?: LockerProviderType;
  deliveryLockerProvider?: LockerProviderType;

  pickupFilter?: ParcelShopFilterWithFormSpecs;
  deliveryFilter?: ParcelShopFilterWithFormSpecs;
};

export type ParcelShop = {
  parcelshop_id: string;
  parcelshop_name: string;
  parcelshop_address: string;
  parcelshop_cod_allowed: string;
};

export type HowToSendFormProps = {
  selectedParcelShop?: ParcelShop;
  onSendToCountryChange?: (country: Country) => void;
};

export type ParcelFormProps = {
  items: Array<ParcelPartDescription>;
};

export enum PaymentOptionType {
  CARD_FORWARD = 'cardForward',
}

export type RecipientFormFields = AddressCommonFormFields & {
  recipient: AddressType;
  phone: string;
  note?: string;
  contactPerson?: string;
};

export type SenderFormFields = Partial<AddressCommonFormFields> & {
  sender?: AddressType;
  ico?: string;
  dic?: string;
  phone?: string;
  invoiceToAnotherAddress?: boolean;
  nameInvoice?: string;
  streetInvoice?: string;
  cityInvoice?: string;
  postalCodeInvoice?: string;
  icoInvoice?: string;
  dicInvoice?: string;
  /** Do not use for reading! For Reading use 'HowToSend.sendHow' This is only for FORM requirements -> TODO: Remove it */
  pickup?: PickupType;
  sendWhen?: string;
  note?: string;
  termsAgreement?: boolean;
  newsletterAgreement?: boolean;
  createAccount?: boolean;
  password?: string;
  selectedParcelShopId?: string;
  selectedParcelShopName?: string;
  selectedParcelShopAddress?: string;
  selectedParcelShopCodAllowed?: string;
};

export type OtherData = {
  appInfoNotShowIn?: string;
  bankAccountDataWasOverridenByUser?: boolean;
  selectedSenderAddressId?: string;
  selectedSenderInvoiceAddressId?: string;
  selectedSenderAddressOverridenByUser?: boolean;
  selectedSenderInvoiceAddressOverridenByUser?: boolean;
  /** Flag, if TRUE, the user does not want to use invoice address even it exists in user's profile */
  selectedSenderInvoiceAddressUsageOverridenByUser?: boolean;
  tmpThxPageData?: { recipient?: RecipientFormFields };
  history?: Array<keyof RoutesType>;
};

export type Dimension = {
  width: number;
  height: number;
  length: number;
};

export type AdditionalServicesFormFields = {
  paymentOption: string;
  fragile: boolean;
  doNotTip: boolean;
  cashOnDelivery: boolean;
  insurance: boolean;
  parcelValues: Array<{ value: number | null; on: boolean }>;
  cashOnDeliveryValue: number;
  accountNumber: string;
  bankCode: string;
  addPaymentDetails: boolean;
  variableSymbol: string;
  iban?: string;
  swift?: string;
};

export type AllFormsData = {
  howToSend: HowToSendFormFields;
  parcel: ParcelFormProps;
  recipient: RecipientFormFields;
  sender: SenderFormFields;
  additionalServices: AdditionalServicesFormFields;
};

export type OpenClosePair = { open: string; close: string };

export type OpeningHours = Partial<Record<DayOfTheWeek, Array<OpenClosePair>>>;

export type ParcelShopPaymentOptionsKeys =
  | 'cardPaymentAllowed'
  | 'codAllowed'
  | 'onlineCodAllowed'
  | 'prepaidAllowed';

export type ParcelShopFeaturesKeys =
  | 'returnAllowed'
  | 'expressAllowed'
  | 'pickupAllowed'
  | 'openOnWeekends'
  | 'openInLateAfternoon';

export type ParcelShopCountry = 'CZ';

export type ParcelShopAddress = {
  city: string;
  street: string;
  zip: string;
  country: Country;
  note?: string;
};

export type Coordinates = { latitude: number; longitude: number };

export type ParcelShopLocation = {
  coordinates: Coordinates;
  address: ParcelShopAddress;
};

export type ParcelShopItemType = {
  id: string;
  enabled: boolean;
  contactInfo?: Partial<Record<'email' | 'name' | 'phone' | 'web', string>>;
  photos: Array<string>;
  note: string | string[];
  type: 'locker' | 'parcelShop';
  lockerProvider: LockerProviderType;
  openingHours: OpeningHours;
  paymentOptions: Record<ParcelShopPaymentOptionsKeys, boolean>;
  location: ParcelShopLocation;
  features: Record<ParcelShopFeaturesKeys, boolean>;
  dropOffAllowed?: boolean;
};
