/**
 * Validation for czech bank account number.
 * https://www.cnb.cz/export/sites/cnb/cs/platebni-styk/.galleries/pravni_predpisy/download/vyhl_169_2011.pdf
 */
const ACCOUNT_NUMBER_WEIGHTS = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];

const validateByWeight = (value: string) => {
  if (parseInt(value, 10) === 0 && value !== '000000') {
    return false;
  }

  const decomposed = [
    ...String(value).padStart(ACCOUNT_NUMBER_WEIGHTS.length, '0'),
  ].map((v) => Number(v));

  if (decomposed.some((v) => Number.isNaN(v))) {
    return false;
  }

  if (decomposed.length > ACCOUNT_NUMBER_WEIGHTS.length) {
    return false;
  }

  const accVal = ACCOUNT_NUMBER_WEIGHTS.reduce(
    (acc, _, i) => acc + ACCOUNT_NUMBER_WEIGHTS[i] * decomposed[i],
    0,
  );

  return accVal % 11 === 0;
};
const bankRegExp = /^(\d{1,6}-)?\d{1,10}$/;

export const validateBankNumber = (value: string) => {
  if (!bankRegExp.test(value)) return false;
  const [part0, part1] = value.split('-');
  return validateByWeight(part1 ?? part0);
};

const ibanRegExp = /^(?<countryCode>[A-Z]{2})(?<checkCode>\d{2})(?<bankAccountNumber>\d{20})$/;

export const SUPPORTED_IBAN_COUNTRIES = ['CZ', 'SK'];

/**
 * Returns true, if IBAN starts with supported country code.
 *
 * @param value IBAN string
 * @returns
 */
export const validateIbanCountry = (value?: string) =>
  SUPPORTED_IBAN_COUNTRIES.includes((value ?? '').substring(0, 2));

/**
 * Returns true, if value is a valid IBAN.
 *
 * @param value String to test
 * @returns
 */
export const validateIban = (value?: string) => {
  if (!value) return false;

  const result = value.match(ibanRegExp);
  if (result) {
    const { countryCode, checkCode, bankAccountNumber } = result.groups;

    const digits = `${bankAccountNumber}${countryCode}00`.replace(
      /[A-Z]/g,
      (letter) => `${letter.charCodeAt(0) - 55}`,
    );

    // Check checksum. Simple % 97 might not work for large numbers.
    const isChecksumValid = 98 - mod97(digits) === parseInt(checkCode);

    return isChecksumValid;
  }

  return false;
};

/**
 * Returns reminder of modulo 97.
 *
 * Credits to: https://stackoverflow.com/a/45189509/1614120
 *
 * @param string String representing the number to perform modulo 97 on.
 * @returns
 */
function mod97(string: string) {
  return Array.from(string)
    .map((c) => parseInt(c))
    .reduce((remainder, value) => (remainder * 10 + value) % 97, 0);
}

const swiftRegExp = new RegExp('^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$');
export const validateSwift = (value: string) => {
  return swiftRegExp.test(value);
};
